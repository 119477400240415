<template>
  <div class="news">
    <van-nav-bar
      :title="title"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="news-main">
      <iframe :src="url"></iframe>
    </div>
  </div>
</template>
<script>
import { showToast, showSuccessToast, showFailToast } from "vant";
export default {
  name: "News",
  data() {
    return {
      Authorization: localStorage.getItem(window.lsn),
      seed: localStorage.getItem(window.ref),
      url: "",
      title: "",
      userName: "",
      userTel: "",
      userAge: "",
      userDate: "",
      userTime: "",
      showCalendar: false,
      showSelect: false,
      isDisabled: false,
      minDate: new Date(parseInt(Date.parse(new Date()) + 86400000 * 1)),
      maxDate: new Date(parseInt(Date.parse(new Date()) + 86400000 * 7)),
      filterList: {
        userTime: {
          value: "",
          option: [
            { text: "8:00 - 10:00", value: "1" },
            { text: "10:00 - 12:00", value: "2" },
            { text: "14:00 - 16:00", value: "3" },
            { text: "16:00 - 18:00", value: "4" },
          ],
        },
      },
    };
  },
  activated() {
    this.url = this.$route.query.url;
    this.title = this.$route.query.title;
  },
  mounted() {
    this.url = this.$route.query.url;
    this.title = this.$route.query.title;
    console.log(this.url, this.title);
  },
  methods: {
    onClickLeft() {
      history.back();
    },
    validator(val) {
      if (val == "") {
        return false;
      }
    },
    showSelectOption(name) {
      this.optionName = name;
      this.columnsOption = this.filterList[name].option;
      this.showSelect = true;
    },
    showCalendarOption(name) {
      this.optionName = name;
      this.showCalendar = true;
    },
    onConfirm(val) {
      console.log(val.selectedValues[0]);
      this.userTime = val.selectedOptions[0].text;
      this.filterList[this.optionName].value = val.selectedValues[0];
      this.showSelect = false;
    },
    onConfirm2(date) {
      if (date) {
        var resData = date.getDate();
        var resMonth = date.getMonth() + 1;
        if (resMonth <= 9) {
          resMonth = "0" + resMonth;
        }
        if (resData <= 9) {
          resData = "0" + resData;
        }
        this.userDate = `${date.getFullYear()}-${resMonth}-${resData}`;
        this.showCalendar = false;
      }
    },
    // 提交表单
    toSubmit() {
      if (this.userName == "") {
        showToast("请填写就诊人姓名");
        return false;
      }
      if (this.userTel == "") {
        showToast("请填写手机号码");
        return false;
      }
      if (this.userAge == "") {
        showToast("请填写年龄");
        return false;
      }
      if (this.userDate == "") {
        showToast("请选择就诊日期");
        return false;
      }
      if (this.userTime == "") {
        showToast("请选择就诊时间");
        return false;
      }
      this.isDisabled = true;
      this.axios({
        url: this.$store.state.api + "/order",
        method: "post",
        data: {
          name: this.name,
          gid: this.id,
          userName: this.userName,
          userTel: this.userTel,
          userAge: this.userAge,
          userDate: this.userDate,
          userTime: this.userTime,
          seed: this.seed,
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: this.Authorization,
        },
      }).then((res) => {
        console.log(res);
        this.isDisabled = false;
        if (res.data) {
          showSuccessToast("预约成功！");
          history.back();
        } else {
          showFailToast("预约失败");
        }
      });
    },
  },
};
</script>

<style lang="scss">
.mark {
  background: #f9f9f9;
  .mark-main {
    height: calc(100vh - 46px);
    background: url(../assets/mark-bg.jpg) center no-repeat;
    background-size: cover;
    .main-title {
      font-size: 30px;
      color: #fff;
      height: 50px;
      padding: 100px 0 50px 0;
      text-align: center;
      font-weight: bold;
      letter-spacing: 5px;
    }
    .main-box {
      padding: 20px 20px;
      .van-cell-group {
        margin-bottom: 10px;
      }
    }
  }
  .mark-submit {
    position: fixed;
    bottom: 30px;
    width: 80%;
    padding: 10%;
    .van-button {
      font-size: 16px;
      letter-spacing: 3px;
    }
  }
}
</style>
